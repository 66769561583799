<template>
  <ul
      id="main-menu-navigation"
      class="nav navbar-nav"
  >
    <component
        v-if="validTab(item)"
        :is="resolveNavComponent(item)"
        v-for="item in items"
        :key="item.header || item.title"
        :item="item"
    />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'
import {mapGetters} from "vuex";
export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
  methods: {
    validTab(item) {
      if (!item.role) return true;
      if (item?.exclusive){
        if (this.getUser.exclusiveness_search){
          return false
        }
      }

      return this.validRoles(this.getUser?.HrDepartment.id, item.role);
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  }

}
</script>
